@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: 'icomoon';
  src: url("./fonts/icomoon-v1.0/fonts/icomoon.eot?72ycmr");
  src: url("./fonts/icomoon-v1.0/fonts/icomoon.eot?72ycmr#iefix") format("embedded-opentype"), url("./fonts/icomoon-v1.0/fonts/icomoon.ttf?72ycmr") format("truetype"), url("./fonts/icomoon-v1.0/fonts/icomoon.woff?72ycmr") format("woff"), url("./fonts/icomoon-v1.0/fonts/icomoon.svg?72ycmr#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icn-profile:before {
  content: "\e900"; }

.icn-delete:before {
  content: "\e901"; }

.icn-view:before {
  content: "\e902"; }

.icn-add-user:before {
  content: "\e903"; }

.icn-sync:before {
  content: "\e904"; }

.icn-zoom:before {
  content: "\e905"; }

.icn-cogwheel:before {
  content: "\e906"; }

.icn-chat:before {
  content: "\e907"; }

.icn-calendar:before {
  content: "\e908"; }

.icn-user:before {
  content: "\e909"; }

.font-weight-100 {
  font-weight: 100; }

.font-weight-200 {
  font-weight: 200; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.font-weight-800 {
  font-weight: 800; }

.font-weight-900 {
  font-weight: 900; }

html {
  scroll-behavior: smooth; }

.container-fluid {
  max-width: 1440px; }

.txt-primary {
  color: #387b8c; }

.bg-dark {
  background-color: #387b8c !important; }

.font-30 {
  font-size: 30px; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  outline: none !important; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

html, body {
  min-height: 100%; }

ol, ul {
  list-style: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  outline: none !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  button:hover {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  a:hover {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

input[type=text], textarea {
  -webkit-appearance: none; }

b, strong {
  font-weight: bold; }

.clearfix {
  clear: both; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.m5 {
  margin: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.m10 {
  margin: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.m15 {
  margin: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.m20 {
  margin: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.m25 {
  margin: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.m30 {
  margin: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.m35 {
  margin: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.m40 {
  margin: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.m45 {
  margin: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.m50 {
  margin: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.m55 {
  margin: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.m60 {
  margin: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.m65 {
  margin: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.p5 {
  padding: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.p10 {
  padding: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.p15 {
  padding: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.p20 {
  padding: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.p25 {
  padding: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.p30 {
  padding: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.p35 {
  padding: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.p40 {
  padding: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.p45 {
  padding: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.p50 {
  padding: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.p55 {
  padding: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.p60 {
  padding: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.p65 {
  padding: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.btnPrimary {
  border-radius: 60px;
  background-color: #387b8c;
  border-color: #387b8c;
  min-width: 160px;
  margin: 20px; }
  .btnPrimary:hover {
    opacity: 0.8;
    background-color: #387b8c; }

.btnPrimary-outline {
  border-radius: 60px;
  border-color: #387b8c;
  min-width: 120px;
  margin: 20px;
  color: #387b8c; }
  .btnPrimary-outline:hover {
    opacity: 0.8;
    background-color: #387b8c; }

.headingPage {
  font-size: 20px; }

.-button-primary {
  color: #fff;
  background-color: #387b8c;
  border-color: #387b8c; }

.form-label {
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

.form-control {
  border-radius: 40px;
  height: 54px;
  font-family: "Roboto", sans-serif; }

.is-required {
  color: #f4516c; }

.invalid-feedback {
  font-weight: 600; }

footer {
  padding: 15px 0;
  background-color: #fff; }
  @media only screen and (max-width: 480px) {
    footer {
      font-size: 14px; } }
  @media only screen and (max-width: 320px) {
    footer {
      font-size: 12px; } }

.wrapper.-homePage .headerTop {
  padding: 12px 0px;
  padding: 0.75rem 0rem; }

.wrapper.-homePage .navbar {
  min-height: 60px; }
  .wrapper.-homePage .navbar .navbar-nav .nav-link {
    color: #fff;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
    .wrapper.-homePage .navbar .navbar-nav .nav-link:hover {
      opacity: 0.8; }
    .wrapper.-homePage .navbar .navbar-nav .nav-link:first-child {
      padding-left: 0; }
    @media (max-width: 991px) {
      .wrapper.-homePage .navbar .navbar-nav .nav-link {
        padding-left: 0;
        padding: 15px 0;
        border-bottom: 1px solid #fff; } }

.wrapper.-homePage .mainPage {
  padding: 30px 0; }

.wrapper.-homePage .headTbl {
  padding: 20px;
  background-color: #f4f3f9; }

.proFileCt {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .proFileCt .imgUser {
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%; }
  .proFileCt__trigger {
    background: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto; }
    .proFileCt__trigger span {
      font-weight: 700;
      vertical-align: middle;
      font-size: 14px;
      margin: 0 10px; }
    .proFileCt__trigger img {
      border-radius: 90px; }

.menuProfile {
  background: #fff;
  position: absolute;
  top: 50px;
  right: 0;
  width: 250px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 100; }
  .menuProfile.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .menuProfile ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .menuProfile li {
    border-bottom: 1px solid #dddddd; }
    .menuProfile li a {
      text-decoration: none;
      color: #333333;
      padding: 15px 20px;
      display: block; }
      .menuProfile li a:hover {
        background-color: #f8f9fa; }

.userLg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: columns;
  -moz-flex-direction: columns;
  -ms-flex-direction: columns;
  flex-direction: columns;
  padding: 15px 20px; }
  .userLg__right {
    margin-left: 15px; }

/*--------------------
Form Search
--------------------*/
.search-form {
  position: relative;
  width: 280px;
  height: 40px;
  border-radius: 40px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #13343d;
  transition: all 0.3s ease; }
  @media (max-width: 991px) {
    .search-form {
      margin: 15px 0;
      width: 100%; } }
  .search-form.focus {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15); }
  .search-form .search-input {
    position: absolute;
    top: 10px;
    left: 38px;
    font-size: 14px;
    background: none;
    color: #fff;
    width: 195px;
    height: 20px;
    border: none;
    appearance: none;
    outline: none; }
    @media (max-width: 991px) {
      .search-form .search-input {
        width: 100%; } }
    .search-form .search-input::-webkit-search-cancel-button {
      appearance: none; }
  .search-form .search-button {
    color: #fff;
    position: absolute;
    top: 10px;
    left: 15px;
    height: 20px;
    width: 20px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none !important;
    cursor: pointer; }

.-loginPage {
  background-color: #fff;
  background-image: url("./images/login/bg_login.jpg");
  background-size: contain;
  background-position: center; }
  .-loginPage .loginContainer {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
    padding: 0.9375rem;
    position: relative;
    max-width: 665px;
    margin: 0 auto; }
    .-loginPage .loginContainer .loginWrap {
      width: 100%;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
      padding: 35px;
      padding: 2.1875rem;
      background-color: #fff; }
      .-loginPage .loginContainer .loginWrap__header {
        text-align: center;
        margin-bottom: 50px;
        margin-bottom: 3.125rem; }
        .-loginPage .loginContainer .loginWrap__header .logo {
          max-width: 270px; }
    .-loginPage .loginContainer .buttonWrap {
      padding: 30px 35px 15px 30px;
      padding: 1.875rem 2.1875rem 0.9375rem 1.875rem;
      text-align: center; }
    .-loginPage .loginContainer .form-control {
      background-color: #ffffff;
      border-color: #ebedf2;
      border-color: #ebedf2;
      border-radius: 28px;
      border-style: solid;
      border-width: 1px;
      color: #575962;
      font-size: 17px;
      font-size: 1.0625rem;
      line-height: 26.25px;
      padding: 14px 21px;
      box-shadow: 0px 3px 20px 0px rgba(115, 76, 234, 0.11);
      height: 56px; }
      .-loginPage .loginContainer .form-control:focus {
        border-color: #387b8c;
        color: #575962;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
    .-loginPage .loginContainer .cprFooter {
      padding: 25px 0px;
      padding: 1.5625rem 0rem; }
      .-loginPage .loginContainer .cprFooter__content {
        font-weight: 400; }

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  color: #333333;
  background-color: #fff; }
  body p {
    font-weight: 400; }
